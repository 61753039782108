import React from 'react';
import './HowItWorks.css';
import Header from './Header';

const HowItWorks = () => {
  return (
    <div className="how-it-works-container">
      <Header activePage="how-it-works" />
      <div className="how-it-works">
        <div className="row">
          <div className="box half-width step-1">
            <img src="step-1-illustration.png" alt="Step 1 Illustration" class="step-1-image"></img>
            <h3>Step 1</h3>
            <h2>Analyze Your Audience</h2>
            <p>RubyBeam reviews your YouTube data to create a profile of your audience’s demographics, interests, and engagement.</p>
          </div>
          <div className="box half-width step-2">
            <img src="step-2-illustration.png" alt="Step 1 Illustration" class="step-2-image"></img>
            <h3>Step 2</h3>
            <h2>Match with Relevant Brands</h2>
            <p>Based on your audience profile, RubyBeam finds brands that align well with your content for relevant partnerships.</p>
          </div>
        </div>
        <div className="row">
          <div className="box third-width step-3">
            <h3>Step 3</h3>
            <h2>Find Key Contact</h2>
            <p>RubyBeam finds key contacts in each brand for direct outreach.</p>
          </div>
          <div className="box third-width step-4">
            <h3>Step 4</h3>
            <h2>Outreach</h2>
            <p>Get tailored, ready-to-send emails crafted for each brand.</p>
          </div>
          <div className="box third-width step-5">
            <h3>Step 5</h3>
            <h2>Grow Sponsorships</h2>
            <p>Streamline your outreach and grow brand partnerships effortlessly.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowItWorks;
