import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './GenerateEmails.css';
import Header from './Header';
import axios from 'axios';

function GenerateEmails() {
  const [userData, setUserData] = useState(null); // Store user details, including name
  const [subscription, setSubscription] = useState(undefined); // Store subscription details, distinguish between null and undefined
  const [emails, setEmails] = useState(0); // Emails remaining
  const [youtubeUrl, setYoutubeUrl] = useState(''); // YouTube URL input
  const [feedbackMessage, setFeedbackMessage] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user) {
      fetchUserData(user._id); // Fetch user and subscription details
    } else {
      navigate('/sign-up'); // Redirect if no user data is found
    }
  }, [navigate]);

  const fetchUserData = async (userId) => {
    try {
      // Fetch user data from customers collection
      const userResponse = await axios.get(`/api/users/${userId}`);
      console.log('User details:', userResponse.data);

      // Attempt to fetch subscription details from subscriptions collection
      let subscriptionResponse;
      try {
        subscriptionResponse = await axios.get(`/api/subscriptions/${userId}/active`);
        console.log('Subscription details:', subscriptionResponse.data);
      } catch (subError) {
        if (subError.response?.status === 404) {
          console.warn('No active subscription found.');
          subscriptionResponse = null; // No active subscription
        } else {
          throw subError; // Re-throw other errors
        }
      }

      // Update state with fetched data
      setUserData(userResponse.data);
      setSubscription(subscriptionResponse ? subscriptionResponse.data : null);
      setEmails(subscriptionResponse?.data?.emails || 0); // Default to 0 if no emails
    } catch (error) {
      console.error('Error fetching user or subscription data:', error.response?.data || error.message);
      setFeedbackMessage('An error occurred while fetching data.');
    }
  };

  const handleGenerateEmails = async () => {
    if (!userData || !subscription) {
      console.error('User or subscription data not found.');
      return;
    }

    if (subscription.plan === 'free-trial' && !youtubeUrl.trim()) {
      setFeedbackMessage('Please provide a valid YouTube URL.');
      return;
    }

    try {
      const response = await axios.post('/api/generate-emails', {
        customerId: userData._id,
        youtubeUrl: youtubeUrl.trim(),
        emailsToGenerate: emails, // Pass the number of emails directly
      });

      console.log('Generate emails response:', response.data);
      setFeedbackMessage('Emails generated successfully!');
      fetchUserData(userData._id); // Refresh user and subscription data

      // Navigate to the emails page after successful generation
      navigate('/emails');
    } catch (error) {
      console.error('Error generating emails:', error.response?.data || error.message);
      setFeedbackMessage('An error occurred while generating emails.');
    }
  };

  return (
    <div className="generate-emails-outer-container">
      <Header />
      <div className="generate-emails-container">
        {userData ? (
          subscription === undefined ? (
            <p>Loading...</p>
          ) : subscription ? (
            <>
              <h2>Welcome {userData.firstName}</h2>
              <h3>You have {emails} emails remaining</h3>

              {/* Show additional message if emails are 0 and subscription is active */}
              {emails === 0 && subscription.status === 'active' && (
                <h3>We’ll notify you when new emails are available.</h3>
              )}

              {/* Show YouTube URL input only for free-trial plan and if emails > 0 */}
              {emails > 0 && subscription.plan === 'free-trial' && (
                <div className="youtube-input-container">
                  <label>
                    Enter a YouTube channel URL:
                    <input
                      type="url"
                      value={youtubeUrl}
                      onChange={(e) => setYoutubeUrl(e.target.value)}
                      placeholder="YouTube Channel URL"
                      required
                    />
                  </label>
                </div>
              )}

              {emails > 0 ? (
                <button onClick={handleGenerateEmails}>Generate Emails</button>
              ) : subscription.status === 'active' ? (
                <button onClick={() => navigate('/pricing')}>Upgrade Subscription</button>
              ) : (
                <button onClick={() => navigate('/pricing')}>Buy Subscription</button>
              )}

              {feedbackMessage && <p className="feedback-message">{feedbackMessage}</p>}
            </>
          ) : (
            <div className="no-subscription-container">
              <h2>Welcome {userData.firstName}</h2>
              <h3>To start creating new emails, please purchase a subscription.</h3>
              <button onClick={() => navigate('/pricing')}>Buy Subscription</button>
            </div>
          )
        ) : (
          <p>Loading...</p>
        )}
      </div>
    </div>
  );
}

export default GenerateEmails;