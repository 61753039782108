// EmailHistory.js

import React, { useEffect, useState, useMemo } from 'react';
import './Emails.css'; // Reusing Emails.css
import Header from './Header';
import axios from 'axios';
import { marked } from 'marked';

function EmailHistory() {
    const [emails, setEmails] = useState([]);
    const [selectedEmail, setSelectedEmail] = useState(null);
    const [expandedGroups, setExpandedGroups] = useState({});

    // Group emails first by status ('Sent', 'Not Sent'), then by request date (month and year), then by channelName
    const groupedEmails = useMemo(() => {
        const groups = {};

        emails.forEach((email) => {
            const status = email.status === 'sent' ? 'Sent' : 'Not Sent';
            const createdAt = email.createdAt ? new Date(email.createdAt) : new Date(0);
            const monthYear = isNaN(createdAt.getTime())
                ? 'Unknown Date'
                : createdAt.toLocaleString('default', { month: 'long', year: 'numeric' });
            const channelName = email.channelName || 'Unknown Channel';

            if (!groups[status]) {
                groups[status] = {};
            }

            if (!groups[status][monthYear]) {
                groups[status][monthYear] = {};
            }

            if (!groups[status][monthYear][channelName]) {
                groups[status][monthYear][channelName] = [];
            }

            groups[status][monthYear][channelName].push(email);
        });

        return groups;
    }, [emails]);


    useEffect(() => {
        document.body.classList.add('emails-page');
        return () => document.body.classList.remove('emails-page');
    }, []);

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user'));
        if (user) {
            fetchEmailHistory(user._id);
        }
    }, []);

    const fetchEmailHistory = async (userId) => {
        try {
            const response = await axios.get(`/api/emails/history/${userId}`);
            if (response.data && Array.isArray(response.data.emails)) {
                const emailsWithHTMLContent = response.data.emails.map((email) => ({
                    ...email,
                    content: marked(email.content, { breaks: true }),
                }));
                setEmails(emailsWithHTMLContent);
                setSelectedEmail(emailsWithHTMLContent[0] || null);
            } else {
                setEmails([]);
                setSelectedEmail(null);
            }
        } catch (error) {
            console.error('Error fetching email history:', error);
            setEmails([]);
            setSelectedEmail(null);
        }
    };

    const handleToggleGroup = (groupKey) => {
        setExpandedGroups((prevState) => ({
            ...prevState,
            [groupKey]: !prevState[groupKey],
        }));
    };

    const handlePrevEmail = () => {
        if (!emails || emails.length === 0) return;
        const currentIndex = emails.findIndex(
            (email) => email._id === selectedEmail._id
        );
        const prevIndex = (currentIndex - 1 + emails.length) % emails.length;
        setSelectedEmail(emails[prevIndex]);
    };

    const handleNextEmail = () => {
        if (!emails || emails.length === 0) return;
        const currentIndex = emails.findIndex(
            (email) => email._id === selectedEmail._id
        );
        const nextIndex = (currentIndex + 1) % emails.length;
        setSelectedEmail(emails[nextIndex]);
    };

    return (
        <div className="root-container">
            <Header activePage="email-history" />
            <div className="emails-container">
                {emails.length > 0 && (
                    <div className="brand-sidebar">
                        {Object.entries(groupedEmails).map(([status, dates]) => (
                            <div key={status} className="status-group">
                                <div
                                    className={`status-group ${status === 'Not Sent' ? 'not-sent-group' : 'sent-group'
                                        }`}
                                    onClick={() => handleToggleGroup(status)}
                                >
                                    {status}
                                </div>
                                {expandedGroups[status] &&
                                    Object.entries(dates).map(([monthYear, channels]) => (
                                        <div key={monthYear} className="month-year-group">
                                            <div
                                                className={`month-year-name ${expandedGroups[`${status}-${monthYear}`] ? '' : 'collapsed'
                                                    }`}
                                                onClick={() =>
                                                    handleToggleGroup(`${status}-${monthYear}`)
                                                }
                                            >
                                                {monthYear}
                                            </div>
                                            {expandedGroups[`${status}-${monthYear}`] &&
                                                Object.entries(channels).map(
                                                    ([channelName, emailsInChannel]) => (
                                                        <div key={channelName} className="channel-group">
                                                            <div
                                                                className={`channel-name ${expandedGroups[
                                                                        `${status}-${monthYear}-${channelName}`
                                                                    ]
                                                                        ? ''
                                                                        : 'collapsed'
                                                                    }`}
                                                                onClick={() =>
                                                                    handleToggleGroup(
                                                                        `${status}-${monthYear}-${channelName}`
                                                                    )
                                                                }
                                                            >
                                                                {channelName}
                                                            </div>
                                                            {expandedGroups[
                                                                `${status}-${monthYear}-${channelName}`
                                                            ] && (
                                                                    <ul>
                                                                        {emailsInChannel.map((email) => (
                                                                            <li
                                                                                key={email._id}
                                                                                className={`brand-item ${selectedEmail &&
                                                                                        selectedEmail._id === email._id
                                                                                        ? 'selected'
                                                                                        : ''
                                                                                    }`}
                                                                                onClick={() => setSelectedEmail(email)}
                                                                            >
                                                                                {email.brandName}
                                                                            </li>
                                                                        ))}
                                                                    </ul>
                                                                )}
                                                        </div>
                                                    )
                                                )}
                                        </div>
                                    ))}
                            </div>
                        ))}
                    </div>
                )}

                {emails.length === 0 ? (
                    <div className="no-emails-container">
                        <div className="no-emails-message">
                            <h2>No emails found in your history.</h2>
                        </div>
                    </div>
                ) : selectedEmail ? (
                    <div className="email-card-wrapper">
                        {/* Left Arrow */}
                        <img
                            src="/arrow.png"
                            alt="Previous"
                            className="arrow arrow-left"
                            onClick={() => handlePrevEmail()}
                        />

                        <div className="email-card">
                            <div className="email-header">{selectedEmail.brandName}</div>
                            <div className="email-section">
                                <label>To</label>
                                <div className="email-input-container">
                                    <div className="email-input">{selectedEmail.to}</div>
                                </div>
                            </div>
                            <div className="email-section">
                                <label>Subject</label>
                                <div className="email-input-container">
                                    <div className="email-input">{selectedEmail.subject}</div>
                                </div>
                            </div>
                            <div className="email-section">
                                <label>Content</label>
                                <div className="email-textarea-container">
                                    <div
                                        className="email-textarea"
                                        dangerouslySetInnerHTML={{
                                            __html: selectedEmail.content,
                                        }}
                                    />
                                </div>
                            </div>
                        </div>

                        {/* Right Arrow */}
                        <img
                            src="/arrow.png"
                            alt="Next"
                            className="arrow arrow-right"
                            onClick={() => handleNextEmail()}
                        />
                    </div>
                ) : null}
            </div>
        </div>
    );
}

export default EmailHistory;
