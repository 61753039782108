import React from 'react';
import './FeaturesSection.css';

function FeaturesSection() {
  return (
    <div className="features-section-container">
      <h2 className="features-heading">Why Use Rubybeam?</h2>
      <div className="features-section">
        <div className="feature-box classification">
          <img src="/clock.png" alt="Save Time Icon" className="feature-icon" />
          <h4>Save Time</h4>
          <p>Rubybeam automates brand analysis and matching, so you can focus on creating.</p>
          <img src="/time_illustration.png" alt="Time Illustration" className="time-illustration" />
        </div>
        <div className="feature-box regression">
          <img src="/business.png" alt="Direct Contacts Icon" className="feature-icon" />
          <h4>Provides Direct Contacts</h4>
          <p>Access key brand contacts to ensure your proposals reach the right people.</p>
          <img src="/email_contact.png" alt="Contact Illustration" className="email-illustration" />
        </div>
        <div className="feature-box time-series">
          <img src="/startup.png" alt="Boost Sponsorship Icon" className="feature-icon" />
          <h4>Boosts Sponsorship Chances</h4>
          <p>Increase deal opportunities by sending up to 100 targeted emails monthly.</p>
          <img src="/rocket.png" alt="Boost Illustration" className="rocket-illustration" />
        </div>
      </div>
    </div>
  );
}

export default FeaturesSection;
