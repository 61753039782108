import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import UserContext from './UserContext';
import './InsertChannels.css';
import Header from './Header';

function InsertChannels() {
  const { user, setUser } = React.useContext(UserContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [channels, setChannels] = useState([]);
  const [channelLimit, setChannelLimit] = useState(0);
  const [loading, setLoading] = useState(true);
  const [successMessage, setSuccessMessage] = useState('');


  useEffect(() => {
    const checkUserAndFetchData = async () => {
      try {
        if (!user) {
          const storedUser = localStorage.getItem('user');
          if (storedUser) {
            setUser(JSON.parse(storedUser));
          } else {
            localStorage.setItem('redirectPath', location.pathname);
            navigate('/auth');
            return;
          }
        }

        if (user?._id) {
          const subRes = await fetch(`/api/subscriptions/${user._id}/active`);
          if (!subRes.ok) throw new Error('Failed to fetch subscription details.');
          const subData = await subRes.json();

          if (subData.status !== 'active' || subData.plan === 'free-trial') {
            alert('You need an active subscription (not free-trial) to access this page.');
            navigate('/subscription');
            return;
          }

          const limit = user.userType === 'agency' ? subData.youtubers : subData.emails;
          setChannelLimit(limit);

          const urlRes = await fetch(`/api/urls/${user._id}`);
          if (!urlRes.ok) throw new Error('Failed to fetch URLs.');
          const urlData = await urlRes.json();

          const filledChannels = new Array(limit).fill('').map((_, i) => urlData.urls?.[i] || '');
          setChannels(filledChannels);
        }
      } catch (error) {
        console.error('Error fetching data:', error.message);
        alert('Failed to fetch data. Please try again.');
      } finally {
        setLoading(false);
      }
    };

    checkUserAndFetchData();
  }, [user, setUser, navigate, location.pathname]);

  const handleChannelsChange = (index, value) => {
    const updatedChannels = [...channels];
    updatedChannels[index] = value;
    setChannels(updatedChannels);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setSuccessMessage(''); // Clear any previous success message
    const nonEmptyChannels = channels.filter((channel) => channel.trim() !== '');
    if (nonEmptyChannels.length === 0) {
      alert('Please provide at least one valid channel URL.');
      return;
    }

    try {
      const saveRes = await fetch(`/api/urls/${user._id}`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ urls: nonEmptyChannels }),
      });

      if (!saveRes.ok) throw new Error('Failed to update URLs.');
      const saveData = await saveRes.json();

      setSuccessMessage('Channels saved successfully!');
      navigate('/generate');
    } catch (error) {
      console.error('Error saving channels:', error.message);
      alert('Failed to save channels. Please try again.');
    }
  };


  if (loading) return <div className="loading">Loading...</div>;

  return (
    <div className="insert-channels-container">
      <Header activePage="emails" />
      <h1 className="insert-channels-header">Insert Your Channels URLs</h1>
      <form onSubmit={handleSubmit} className="insert-channels-form">
        <div className="insert-channels-columns">
          {channels.map((channel, index) => (
            <div key={index} className="insert-channels-input-group">
              <label className="insert-channels-label">{index + 1}.</label>
              <input
                type="text"
                className="insert-channels-input"
                value={channel}
                onChange={(e) => handleChannelsChange(index, e.target.value)}
              />
            </div>
          ))}
        </div>
        <button type="submit" className="insert-channels-button">
          Save
        </button>
        {successMessage && <p className="success-message">{successMessage}</p>}
      </form>
    </div>
  );
}

export default InsertChannels;