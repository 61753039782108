import React from 'react';
import Header from './Header';
import IntroSection from './IntroSection';
import FeaturesSection from './FeaturesSection';
import './HomePage.css';


function HomePage() {
  return (
    <div className="homepage-container">
      <Header activePage="home" />
      <IntroSection />
      <FeaturesSection />
    </div>
  );
}

export default HomePage;
