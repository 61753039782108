import React, { useState, useEffect } from 'react';
import Header from './Header';
import './Dashboard.css';

function Dashboard() {
  const [sentEmailsCount, setSentEmailsCount] = useState(0);
  const [brands, setBrands] = useState([]);

  useEffect(() => {
    // Retrieve the user object from localStorage
    const user = JSON.parse(localStorage.getItem('user'));
    const userId = user ? user._id : null;
    console.log('Dashboard userId:', userId);

    if (userId) {
      // Fetch sent emails for the user
      fetch(`/api/emails?userId=${userId}&status=sent`)
        .then(response => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json();
        })
        .then(data => {
          console.log('Emails data:', data);
          // Update the sent emails count
          setSentEmailsCount(data.count);

          // Extract unique brand names from the emails
          const brandNames = [...new Set(data.emails.map(email => email.brandName))];
          setBrands(brandNames);
        })
        .catch(error => {
          console.error('Error fetching emails:', error);
        });
    } else {
      console.error('No userId found in localStorage');
    }
  }, []);

  return (
    <div className="dashboard-container">
      <Header activePage="dashboard" />
      <div className="dashboard-content">
        <h1>Dashboard</h1>
        <p>
          You have sent <strong>{sentEmailsCount}</strong> outreach emails so far.
        </p>
        {brands.length > 0 && (
          <div>
            <p>You've sent emails to the following companies:</p>
            <ul>
              {brands.map((brand, index) => (
                <li key={index}>{brand}</li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
}

export default Dashboard;
