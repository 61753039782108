import React, { useEffect, useState } from 'react';
import './PurchaseEmails.css';
import { useNavigate } from 'react-router-dom';

function PurchaseEmails() {
  const [userData, setUserData] = useState(null);
  const [influencersCount, setInfluencersCount] = useState(1); // Default 1 influencer for agency
  const navigate = useNavigate();

  // Fetch user data on component mount
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user) {
      setUserData(user);
    } else {
      // If no user data, navigate back to login or sign-up
      navigate('/sign-up');
    }
  }, [navigate]);

  // Base prices for agencies (half the price)
  const basePrices = {
    basic: 50,
    standard: 115,
    premium: 200
  };

  // Calculate total price based on the number of influencers for agency
  const calculatePrice = (basePrice) => {
    return basePrice * influencersCount;
  };

  return (
    <div className="purchase-emails-container">
      <h2>Choose Your Subscription</h2>

      {userData?.userType === 'agency' && (
        <div className="influencers-input">
          <label>
            Number of influencers:
            <input
              type="number"
              min="1"
              value={influencersCount}
              onChange={(e) => setInfluencersCount(e.target.value)}
            />
          </label>
        </div>
      )}

      <div className="pricing-plans">
        {/* Basic Plan */}
        <div className="plan basic">
          <h3>Basic</h3>
          <p className="emails"><strong>10 Emails</strong></p>
          <p className="price">
            {userData?.userType === 'agency'
              ? `$${calculatePrice(basePrices.basic)}/month`
              : "$100/month"}
          </p>
          <button className="buy-now-button">Buy Now</button>
        </div>

        {/* Standard Plan */}
        <div className="plan standard">
          <h3>Standard</h3>
          <p className="emails"><strong>25 Emails</strong></p>
          <p className="price">
            {userData?.userType === 'agency'
              ? `$${calculatePrice(basePrices.standard)}/month`
              : "$230/month"}
          </p>
          <button className="buy-now-button">Buy Now</button>
        </div>

        {/* Premium Plan */}
        <div className="plan premium">
          <h3>Premium</h3>
          <p className="emails"><strong>50 Emails</strong></p>
          <p className="price">
            {userData?.userType === 'agency'
              ? `$${calculatePrice(basePrices.premium)}/month`
              : "$400/month"}
          </p>
          <button className="buy-now-button">Buy Now</button>
        </div>
      </div>
    </div>
  );
}

export default PurchaseEmails;
