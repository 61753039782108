import React, { useState, useEffect, useContext } from 'react';
import UserContext from './UserContext';
import { useNavigate } from 'react-router-dom';
import Header from './Header'; // Import the Header component
import './Settings.css'; // Import the new CSS file

function Settings() {
  const { user, setUser } = useContext(UserContext);
  const [firstName, setFirstName] = useState('');
  const [agencyName, setAgencyName] = useState('');
  const [channels, setChannels] = useState([]);
  const [subscription, setSubscription] = useState(null);
  const [loading, setLoading] = useState(true);
  const [youtubeUrl, setYoutubeUrl] = useState('');
  const [successMessage, setSuccessMessage] = useState('');


  const navigate = useNavigate();

  useEffect(() => {
    const checkUser = async () => {
      if (!user) {
        const storedUser = localStorage.getItem('user');
        if (storedUser) {
          setUser(JSON.parse(storedUser));
        } else {
          navigate('/auth'); // Redirect to login if user is not found
          return;
        }
      }

      if (user?._id) {
        console.log('User after setting from localStorage:', user);

        try {
          const userResponse = await fetch(`/api/users/${user._id}`);
          if (!userResponse.ok) {
            const errorData = await userResponse.json();
            throw new Error(errorData.error || 'Failed to fetch user data');
          }

          const userData = await userResponse.json();
          setFirstName(userData.firstName || '');
          setAgencyName(userData.agencyName || '');

          // Fetch subscription
          const subResponse = await fetch(`/api/subscriptions/${user._id}/active`);
          if (!subResponse.ok) throw new Error('Failed to fetch active subscription');
          const subData = await subResponse.json();
          setSubscription(subData);

          if (userData.userType === 'youtuber') {
            const urlResponse = await fetch(`/api/urls/${user._id}`);
            if (!urlResponse.ok) throw new Error('Failed to fetch YouTube URL');
            const urlData = await urlResponse.json();

            console.log('Fetched YouTube URLs:', urlData.urls); // Debugging log
            setYoutubeUrl(urlData.urls?.[0] || ''); // Ensure the first URL is used
          } else if (userData.userType === 'agency') {
            const urlResponse = await fetch(`/api/urls/${user._id}`);
            if (!urlResponse.ok) throw new Error('Failed to fetch channels');
            const urlData = await urlResponse.json();

            console.log('Fetched agency URLs:', urlData.urls); // Debugging log
            const channelLimit = subData?.youtubers || 0;

            // Safeguard for missing or empty URLs, ensure we fill with empty strings if needed
            const filledChannels = new Array(channelLimit).fill('').map((_, i) => urlData.urls?.[i] || '');
            setChannels(filledChannels);
          }

        } catch (err) {
          console.error('Error fetching data:', err);
        } finally {
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    };

    checkUser();
  }, [user, navigate, setUser]);



  const handleDetailsUpdate = async (e) => {
    e.preventDefault();
    setSuccessMessage(''); // Clear previous success message
    try {
      const body = { firstName, agencyName: user.userType === 'agency' ? agencyName : null };
      const response = await fetch(`/api/users/${user._id}`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body),
      });

      if (!response.ok) {
        const responseText = await response.text();
        throw new Error(responseText || 'Failed to update user');
      }

      const data = await response.json();
      setUser(data.user);
      localStorage.setItem('user', JSON.stringify(data.user));

      // Update success message
      setSuccessMessage(data.message || 'Details updated successfully!');
    } catch (err) {
      console.error('Error updating details:', err);
      setSuccessMessage('Failed to update details. Please try again.');
    }
  };




  const handleSubscriptionChange = () => navigate('/pricing');

  const handleSubscriptionCancel = async () => {
    try {
      const response = await fetch(`/api/subscriptions/${user._id}/cancel`, { method: 'PUT' });
      if (!response.ok) throw new Error('Failed to cancel subscription');
      setSubscription((prev) => ({ ...prev, status: 'inactive', plan: 'No Plan' }));
      alert('Subscription canceled successfully!');
    } catch (err) {
      console.error('Error canceling subscription:', err);
      alert('Error canceling subscription. Please try again later.');
    }
  };

  const getChannelLimit = (plan) => {
    switch (plan) {
      case 'agency-basic':
        return 10;
      case 'agency-plus':
        return 20;
      case 'agency-pro':
        return 30;
      default:
        return 0;
    }
  };

  if (loading) return <p>Loading...</p>;

  return (
    <div className="settings-outer-container">
      <Header />
      <div className="settings-container">
        <h2 className="settings-h2">Settings</h2>
        <div className="settings-content">
          <div className="settings-left">
            <div className="details-section">
              <h3>Personal Details</h3>
              <form onSubmit={handleDetailsUpdate} className="settings-form">
                <div className="input-section">
                  <h4>First Name</h4>
                  <input
                    type="text"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    placeholder="First Name"
                    className="settings-input"
                  />
                </div>
                {user?.userType === 'agency' && (
                  <div className="input-section">
                    <h4>Agency Name</h4>
                    <input
                      type="text"
                      value={agencyName}
                      onChange={(e) => setAgencyName(e.target.value)}
                      placeholder="Agency Name"
                      className="settings-input"
                    />
                  </div>
                )}
                <button type="submit" className="settings-button">Save Details</button>
                {successMessage && <p className="success-message">{successMessage}</p>}
              </form>

            </div>

            <div className="subscription-section">
              <h3>Subscription</h3>
              {subscription && subscription.status ? (
                <>
                  <p>Plan: {subscription.status === 'inactive' ? 'No Plan' : subscription.plan}</p>
                  {subscription.status === 'active' ? (
                    <>
                      <button onClick={handleSubscriptionChange} className="settings-button">
                        Change Subscription
                      </button>
                      <button onClick={handleSubscriptionCancel} className="settings-button">
                        Cancel Subscription
                      </button>
                    </>
                  ) : (
                    <button onClick={() => navigate('/pricing')} className="settings-button">
                      Renew Subscription
                    </button>
                  )}
                </>
              ) : (
                <p>No active subscription</p>
              )}
            </div>
          </div>
          <div className="settings-right">
            {user?.userType === 'agency' && (
              <>
                <h3>Your Channels</h3>
                <button
                  className="settings-button"
                  onClick={() => navigate('/insert-channels')}
                >
                  Update Channel URLs
                </button>
              </>
            )}

          </div>
        </div>
      </div>
    </div>
  );
}

export default Settings;