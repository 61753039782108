// Emails.js

import React, { useEffect, useState, useMemo, useRef, useCallback } from 'react';
import './Emails.css';
import Header from './Header';
import axios from 'axios';
import { marked } from 'marked';


function Emails() {
  const [emails, setEmails] = useState([]);
  const [selectedEmail, setSelectedEmail] = useState(null);
  const [authenticated, setAuthenticated] = useState(false);
  const [expandedChannels, setExpandedChannels] = useState({});
  const [isEditingSubject, setIsEditingSubject] = useState(false);
  const [isEditingContent, setIsEditingContent] = useState(false);

  const contentEditableRef = useRef(null);

  const groupedEmails = useMemo(() => {
    const groups = {};
    emails.forEach((email) => {
      const channelName = email.channelName || 'Unknown Channel';
      if (!groups[channelName]) {
        groups[channelName] = [];
      }
      groups[channelName].push(email);
    });
    return groups;
  }, [emails]);

  useEffect(() => {
    if (emails.length > 0 && Object.keys(expandedChannels).length === 0) {
      const initialExpandedChannels = {};
      Object.keys(groupedEmails).forEach((channelName) => {
        initialExpandedChannels[channelName] = false;
      });
      setExpandedChannels(initialExpandedChannels);
    }
  }, [emails, groupedEmails, expandedChannels]);

  useEffect(() => {
    document.body.classList.add('emails-page');
    return () => document.body.classList.remove('emails-page');
  }, []);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user) {
      fetchEmails(user._id);
    }
  }, []);

  const sendEmail = useCallback(async () => {
    const user = JSON.parse(localStorage.getItem('user'));
  
    try {
      await axios.post('/api/send-email', {
        emailId: selectedEmail._id,
        to: selectedEmail.to,
        subject: selectedEmail.subject,
        content: selectedEmail.content,
        userId: user._id,
      });
  
      alert('Email sent successfully!');
      fetchEmails(user._id);
    } catch (error) {
      console.error('Error sending email:', error);
      alert('Failed to send email.');
    }
  }, [selectedEmail]); // Add `selectedEmail` as a dependency
  

  const sendAllEmails = useCallback(async () => {
    const user = JSON.parse(localStorage.getItem('user'));
  
    try {
      await axios.post('/api/send-all-emails', {
        userId: user._id,
        emails, // Send the updated emails
      });
  
      alert('All emails sent successfully!');
      fetchEmails(user._id);
    } catch (error) {
      console.error('Error sending all emails:', error);
      alert('Failed to send all emails.');
    }
  }, [emails]); // Add `emails` as a dependency
  

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const status = urlParams.get('status');
    if (status === 'success') {
      setAuthenticated(true);

      const pendingAction = localStorage.getItem('pendingAction');
      if (pendingAction === 'sendAllEmails') {
        localStorage.removeItem('pendingAction');
        sendAllEmails();
      } else if (pendingAction === 'sendEmail') {
        localStorage.removeItem('pendingAction');
        sendEmail();
      } else {
        alert('Email sent successfully!');
      }
    } else if (status === 'failed') {
      alert('Failed to send email.');
    }

    window.history.replaceState({}, document.title, '/emails');
  }, [sendAllEmails, sendEmail]);

  const handleEmailFieldChange = (field, value) => {
    setSelectedEmail((prevEmail) => ({
      ...prevEmail,
      [field]: value,
    }));
  };

  const fetchEmails = async (userId) => {
    try {
        const response = await axios.get(`/api/emails/${userId}`);
        if (response.data && Array.isArray(response.data.emails)) {
            const emailsWithHTMLContent = response.data.emails.map((email) => ({
                ...email,
                content: marked(email.content, { breaks: true }),
            }));
            setEmails(emailsWithHTMLContent);
            setSelectedEmail(emailsWithHTMLContent[0] || null);
        } else {
            setEmails([]);
            setSelectedEmail(null);
        }
    } catch (error) {
        console.error('Error fetching emails:', error);
        setEmails([]);
        setSelectedEmail(null);
    }
};


  const handleToggleChannel = (channelName) => {
    setExpandedChannels((prevState) => ({
      ...prevState,
      [channelName]: !prevState[channelName],
    }));
  };

  const fetchAuthUrl = async () => {
    try {
      if (!selectedEmail) {
        alert('No email selected!');
        return;
      }

      const { to, subject, content, _id } = selectedEmail;
      const user = JSON.parse(localStorage.getItem('user'));

      const response = await axios.get(
        `/api/auth/gmail?to=${encodeURIComponent(
          to
        )}&subject=${encodeURIComponent(
          subject
        )}&content=${encodeURIComponent(
          content
        )}&userId=${encodeURIComponent(user._id)}&emailId=${encodeURIComponent(_id)}`
      );
      const authUrl = response.data.authUrl;

      window.location.href = authUrl;
    } catch (error) {
      console.error('Error fetching Gmail auth URL:', error);
      alert('Failed to fetch Gmail authorization URL.');
    }
  };

  const fetchAuthUrlForAuth = async () => {
    try {
      const user = JSON.parse(localStorage.getItem('user'));
      const response = await axios.get(
        `/api/auth/gmail?userId=${encodeURIComponent(user._id)}`
      );
      const authUrl = response.data.authUrl;

      window.location.href = authUrl;
    } catch (error) {
      console.error('Error fetching Gmail auth URL:', error);
      alert('Failed to fetch Gmail authorization URL.');
    }
  };



  const handleSendEmail = () => {
    if (!authenticated) {
      fetchAuthUrl();
    } else {
      sendEmail();
    }
  };

  const handleSendAllEmails = () => {
    if (!authenticated) {
      localStorage.setItem('pendingAction', 'sendAllEmails');
      fetchAuthUrlForAuth();
    } else {
      sendAllEmails();
    }
  };

  const handlePrevEmail = () => {
    if (!emails || emails.length === 0) return;
    const currentIndex = emails.findIndex(
      (email) => email._id === selectedEmail._id
    );
    const prevIndex = (currentIndex - 1 + emails.length) % emails.length;
    setSelectedEmail(emails[prevIndex]);
  };

  const handleNextEmail = () => {
    if (!emails || emails.length === 0) return;
    const currentIndex = emails.findIndex(
      (email) => email._id === selectedEmail._id
    );
    const nextIndex = (currentIndex + 1) % emails.length;
    setSelectedEmail(emails[nextIndex]);
  };

  const handleContentBlur = () => {
    if (contentEditableRef.current) {
      const updatedContent = contentEditableRef.current.innerHTML;
      handleEmailFieldChange('content', updatedContent);
    }
    setIsEditingContent(false);
  };

  return (
    <div className="root-container">
      <Header activePage="emails" />
      <div className="emails-container">
        {emails.length > 0 && (
          <div className="brand-sidebar">
            {Object.entries(groupedEmails).map(
              ([channelName, emailsInChannel]) => (
                <div key={channelName} className="channel-group">
                  <div
                    className={`channel-name ${
                      !expandedChannels[channelName] ? 'collapsed' : ''
                    }`}
                    onClick={() => handleToggleChannel(channelName)}
                  >
                    {channelName}
                  </div>
                  {expandedChannels[channelName] && (
                    <ul>
                      {emailsInChannel.map((email) => (
                        <li
                          key={email._id}
                          className={`brand-item ${
                            selectedEmail && selectedEmail._id === email._id
                              ? 'selected'
                              : ''
                          }`}
                          onClick={() => setSelectedEmail(email)}
                        >
                          {email.brandName}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              )
            )}
          </div>
        )}

        {emails.length === 0 ? (
          <div className="no-emails-container">
            <div className="no-emails-message">
              <h2>
                You've sent all emails. We will notify you when new emails are
                ready.
              </h2>
              <h3>
                Want more emails?{' '}
                <a href="/settings" className="upgrade-link">
                  Upgrade plan
                </a>
              </h3>
            </div>
          </div>
        ) : selectedEmail ? (
          <div className="email-card-wrapper">
            {/* Left Arrow */}
            <img
              src="/arrow.png"
              alt="Previous"
              className="arrow arrow-left"
              onClick={() => handlePrevEmail()}
            />

            <div className="email-card">
              <div className="email-header">{selectedEmail.brandName}</div>
              <div className="email-section">
                <label>To</label>
                <div className="email-input-container">
                  <div className="email-input">{selectedEmail.to}</div>
                </div>
              </div>
              <div className="email-section">
                <label>Subject</label>
                <div className="email-input-container">
                  {isEditingSubject ? (
                    <input
                      type="text"
                      className="email-input"
                      value={selectedEmail.subject}
                      onChange={(e) =>
                        handleEmailFieldChange('subject', e.target.value)
                      }
                      onBlur={() => setIsEditingSubject(false)}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          setIsEditingSubject(false);
                        }
                      }}
                    />
                  ) : (
                    <div
                      className="email-input"
                      onClick={() => setIsEditingSubject(true)}
                    >
                      {selectedEmail.subject}
                    </div>
                  )}
                </div>
              </div>
              <div className="email-section">
                <label>Content</label>
                <div className="email-textarea-container">
                  {isEditingContent ? (
                    <div
                      className="email-textarea"
                      contentEditable
                      ref={contentEditableRef}
                      onBlur={handleContentBlur}
                      suppressContentEditableWarning
                      dangerouslySetInnerHTML={{
                        __html: selectedEmail.content,
                      }}
                    />
                  ) : (
                    <div
                      className="email-textarea"
                      onClick={() => setIsEditingContent(true)}
                      dangerouslySetInnerHTML={{
                        __html: selectedEmail.content,
                      }}
                    />
                  )}
                </div>
              </div>
            </div>

            {/* Right Arrow */}
            <img
              src="/arrow.png"
              alt="Next"
              className="arrow arrow-right"
              onClick={() => handleNextEmail()}
            />

            <div className="email-buttons">
              <button
                className="email-button send-email"
                onClick={handleSendEmail}
              >
                Send Email
              </button>
              <button
                className="email-button send-all-emails"
                onClick={handleSendAllEmails}
              >
                Send All Emails
              </button>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default Emails;
