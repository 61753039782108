import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './ResetPassword.css';
import Header from './Header';

function ResetPassword() {
  const navigate = useNavigate();
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState(''); // Error message state
  const [successMessage, setSuccessMessage] = useState(''); // Success message state

  const handleResetPassword = () => {
    // Clear previous messages
    setErrorMessage('');
    setSuccessMessage('');

    if (newPassword !== confirmPassword) {
      setErrorMessage('Passwords do not match!');
      return;
    }

    const searchParams = new URLSearchParams(window.location.search);
    const token = searchParams.get('token');

    fetch('/api/reset-password', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ token, newPassword }),
    })
      .then((response) => {
        if (!response.ok) {
          return response.json().then((errorData) => {
            throw new Error(errorData.error || 'Failed to reset password.');
          });
        }
        setSuccessMessage('Password reset successfully! Redirecting to login...');
        setTimeout(() => {
          navigate('/?mode=login'); // Redirect after success
        }, 3000); // Delay to show success message
      })
      .catch((error) => {
        console.error('Password reset error:', error);
        setErrorMessage('Error resetting password. Please try again.');
      });
  };

  return (
    <div className="outer">
          <Header />

    <div className="reset-password-page">
      <h2>Reset Your Password</h2>
      <div className="reset-password-container">
        <input
          type="password"
          placeholder="New Password"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          className={`password-input ${errorMessage ? 'input-error' : ''}`}
        />
        <input
          type="password"
          placeholder="Confirm Password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          className={`password-input ${errorMessage ? 'input-error' : ''}`}
        />
        {errorMessage && <p className="error-text">{errorMessage}</p>} {/* Error message */}
        {successMessage && <p className="success-text">{successMessage}</p>} {/* Success message */}
        <button onClick={handleResetPassword}>Reset Password</button>
      </div>
    </div>
    </div>
  );
}

export default ResetPassword;