import React, { useState, useEffect } from 'react';
import './ContactUs.css';
import Header from './Header';

function ContactUs() {
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    message: '',
  });

  const [status, setStatus] = useState('');

  useEffect(() => {
    document.body.classList.add('contact-page');
    return () => document.body.classList.remove('contact-page');
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch('/api/contact', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        setStatus('Message sent successfully!');
        setFormData({ fullName: '', email: '', message: '' }); // Reset the form
      } else {
        const { error } = await response.json();
        setStatus(`Failed to send message: ${error}`);
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      setStatus('An error occurred. Please try again.');
    }
  };

  return (
    <div className="page-container">
      <Header activePage="emails" />
      <h2 className="contact-h2">Contact Us</h2>
      <p className="contact-p">
        We're here for anything you need. Just drop us a quick message below.
        We'll get back in 24 hrs.
      </p>
      <div className="contact-form-container">
        <form className="contact-form" onSubmit={handleSubmit}>
          <label htmlFor="fullName">Full Name</label>
          <input
            type="text"
            id="fullName"
            name="fullName"
            placeholder="Enter your name"
            value={formData.fullName}
            onChange={handleInputChange}
            required
          />

          <label htmlFor="email">Email</label>
          <input
            type="email"
            id="email"
            name="email"
            placeholder="john@example.com"
            value={formData.email}
            onChange={handleInputChange}
            required
          />

          <label htmlFor="message">Message</label>
          <textarea
            id="message"
            name="message"
            placeholder="Enter your message here"
            rows="5"
            value={formData.message}
            onChange={handleInputChange}
            required
          ></textarea>

          <button type="submit" className="submit-button">Submit</button>
        </form>
      </div>
      {status && <p className="form-status">{status}</p>}
      <p className="alternate-contact">
        You can also reach out to us at <a href="mailto:hello@rubybeam.com">hello@rubybeam.com</a>.
      </p>
    </div>
  );
}

export default ContactUs;
