import React, { useState, useEffect, useContext } from 'react';
import './SignUp.css';
import { useNavigate, useLocation } from 'react-router-dom';
import Header from './Header';
import UserContext from './UserContext';

function SignUp() {
  const location = useLocation();
  const navigate = useNavigate();
  const { setUser } = useContext(UserContext);

  const [formState, setFormState] = useState({
    firstName: '',
    email: '',
    password: '',
    agencyName: '',
    userType: 'agency', // Always set to agency
  });

  const [isLoginMode, setIsLoginMode] = useState(new URLSearchParams(location.search).get('mode') === 'login');
  const [forgotPasswordMode, setForgotPasswordMode] = useState(false);
  const [resetEmail, setResetEmail] = useState('');
  const [resetError, setResetError] = useState('');
  const [resetSuccess, setResetSuccess] = useState(''); // Success message state

  const { redirectTo } = location.state || {};

  useEffect(() => {
    setIsLoginMode(new URLSearchParams(location.search).get('mode') === 'login');
  }, [location.search]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormState({
      ...formState,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (isLoginMode) {
      const { email, password } = formState;

      fetch('/api/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      })
        .then((response) => {
          if (!response.ok) {
            return response.json().then((errorData) => {
              throw new Error(errorData.error || 'Login failed');
            });
          }
          return response.json();
        })
        .then((data) => {
          localStorage.setItem('user', JSON.stringify(data));
          setUser(data);
          navigate(redirectTo || '/');
        })
        .catch((error) => {
          console.error('Login error:', error);
          alert('Error during login. Please try again.');
        });
    } else {
      fetch('/api/customer-sign-up', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ ...formState, emailsRemaining: 3 }),
      })
        .then((response) => {
          if (!response.ok) {
            return response.json().then((errorData) => {
              throw new Error(errorData.error || 'Sign-up failed');
            });
          }
          return response.json();
        })
        .then((data) => {
          const { user } = data;
          localStorage.setItem('user', JSON.stringify(user));
          setUser(user);
          navigate(redirectTo || '/dashboard');
        })
        .catch((error) => {
          console.error('Sign-up error:', error);
          alert('There was an error during sign-up. Please try again.');
        });
    }
  };

  const handleResetPasswordSubmit = (email) => {
    fetch('/api/send-reset-password-email', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email }),
    })
      .then((response) => {
        if (!response.ok) {
          if (response.status === 404) {
            throw new Error('The email you entered is not associated with any account.');
          }
          throw new Error('Failed to send reset email. Please try again.');
        }
        return response.json();
      })
      .then(() => {
        setResetSuccess('Reset password email sent. Check your inbox!');
        setResetError('');
      })
      .catch((error) => {
        console.error('Reset email error:', error);
        setResetError(error.message);
        setResetSuccess(''); // Clear success message if there's an error
      });
  };

  return (
    <div className="signup-outer-container">
      <Header />
      <div className="signup-container">
        <div className="content">
          <h2 className="signup-h2">
            {forgotPasswordMode ? 'Reset Password' : isLoginMode ? 'Log In' : 'Sign Up'}
          </h2>

          {forgotPasswordMode ? (
            <div className="reset-password-container">
              <input
                type="email"
                name="resetEmail"
                value={resetEmail}
                onChange={(e) => {
                  setResetEmail(e.target.value);
                  setResetError('');
                  setResetSuccess(''); // Clear success message on input change
                }}
                placeholder="Enter your email"
                required
                className={`reset-email-input ${resetError ? 'input-error' : ''}`}
              />
              {resetError && <p className="error-text">{resetError}</p>}
              {resetSuccess && <p className="reset-success-text">{resetSuccess}</p>} {/* Success message */}
              <button
                className="reset-password-button"
                onClick={() => handleResetPasswordSubmit(resetEmail)}
              >
                Submit
              </button>
              <button
                className="cancel-reset-button"
                onClick={() => setForgotPasswordMode(false)}
              >
                Cancel
              </button>
            </div>
          ) : (
            <form onSubmit={handleSubmit} className="signup-form">
              {!isLoginMode && (
                <div className="input-row">
                  <input
                    type="text"
                    name="firstName"
                    value={formState.firstName}
                    onChange={handleChange}
                    placeholder="First Name"
                    required
                    className="first-name-input"
                  />
                </div>
              )}

              <input
                type="email"
                name="email"
                value={formState.email}
                onChange={(e) => setFormState({ ...formState, email: e.target.value })}
                placeholder="Email"
                required
                className="email-input"
              />
              <input
                type="password"
                name="password"
                value={formState.password}
                onChange={(e) => setFormState({ ...formState, password: e.target.value })}
                placeholder="Password"
                required
                className="password-input"
              />

              {!isLoginMode && (
                <input
                  type="text"
                  name="agencyName"
                  value={formState.agencyName}
                  onChange={handleChange}
                  placeholder="Agency Name"
                  required
                  className="agency-name-input"
                />
              )}

              <button type="submit">{isLoginMode ? 'Log In' : 'Sign Up'}</button>
            </form>
          )}

          {isLoginMode && !forgotPasswordMode && (
            <p className="forgot-password-text">
              Forgot your password?{' '}
              <button
                className="forgot-password-button"
                onClick={() => setForgotPasswordMode(true)}
              >
                Reset Password
              </button>
            </p>
          )}

          {!forgotPasswordMode && (
            <p className="toggle-text">
              {isLoginMode ? "Don't have an account?" : 'Already have an account?'}
              <button className="toggle-button" onClick={() => setIsLoginMode(!isLoginMode)}>
                {isLoginMode ? 'Sign Up' : 'Log In'}
              </button>
            </p>
          )}
        </div>
      </div>
    </div>
  );
}

export default SignUp;